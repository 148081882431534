import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { resetErrors, sendStep, resetCreation } from "../store/actions/homeActions";

import { useHistory, useLocation } from 'react-router-dom';

function CreateEbook(props) {


  const location = useLocation();
  const { daje, niche_2, selectedSubNiche_2, selectedTitle_2, audience_2, previewstate_2 } = location.state || {};


  const { token, error_in_creation, sendStepFN, sub_niches, resetErrorsFn, titles, preview, queued, resetCreationFn } = props;
  const [errore, seterrore] = useState("");
  const [niche, setniche] = useState("");
  const [audience, setaudience] = useState("");

  const canvaRef = useRef();


  const [step, setstep] = useState(0);

  const [subniches, setsubniches] = useState([]);
  const [titlesarr, settitles] = useState([]);


  const [selectedSubNiche, setSelectedSubNiche] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [previewstate, setpreview] = useState([]);

  const [isloading, setisloading] = useState(false);


  const history = useHistory();




  useEffect(() => {





    seterrore(error_in_creation);
    setisloading(false);

    setTimeout(() => {
      resetErrorsFn();
    }, 8000);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_in_creation]);


  useEffect(() => {

    if (niche) {

      setstep(1);

    }
    setsubniches(sub_niches);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_niches]);


  useEffect(() => {

    if (audience) {

      setstep(3);

    }
    settitles(titles);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titles]);


  useEffect(() => {

    if (selectedTitle !== '') {

      setstep(4);

    }

    setpreview(preview);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);


  useEffect(() => {

    if (queued) {

      setstep(5);

    }

    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queued]);



  function next(i) {
    setisloading(true);
    seterrore("");

    if (i === 0) {
      //step 1
      if (niche.length < 1) {

        seterrore("Please enter a topic or niche to continue.");
        setisloading(false);

      }
      else {
        setsubniches([]);
        sendStepFN(0, niche, null);
      }
    }


    else if (i === 1) {
      //step 2
      if (selectedSubNiche === '') {
        seterrore("Please select a sub niche.");
        setisloading(false);

      }
      else {
        //setsubniches([]);
        //sendStepFN(0, niche);
        setstep(2);
        setisloading(false);

      }
    }


    else if (i === 2) {
      //step 3
      if (audience.length < 1) {

        seterrore("Please enter an audience to continue.");
        setisloading(false);

      }
      else {
        //setsubniches([]);
        sendStepFN(1, selectedSubNiche, audience);
      }
    }


    else if (i === 3) {
      //step 3
      if (selectedTitle === '') {

        seterrore("Please select a title to continue.");
        setisloading(false);

      }
      else {
        //setsubniches([]);
        sendStepFN(2, selectedSubNiche, audience, selectedTitle);
      }
    }



    else if (i === 4) {
      //step 3

      if (!token) {
        canvaRef.current.open();
        setisloading(false);
        /*
        sessionStorage.setItem('not_logged', true);
        sessionStorage.setItem('step', 1);
        sessionStorage.setItem('niche', niche);
        sessionStorage.setItem('selectedSubNiche', selectedSubNiche);
        sessionStorage.setItem('selectedTitle', selectedTitle);
        sessionStorage.setItem('audience', audience);
        sessionStorage.setItem('previewstate', JSON.stringify(previewstate));
        */
        return false;

      }

      sendStepFN(3, niche, selectedSubNiche, selectedTitle, audience, previewstate);

    }


  }


  useEffect(() => {
    if (subniches && subniches.length > 0) {
      setSelectedSubNiche(subniches[0].sub_niche);
    }
  }, [subniches]);


  useEffect(() => {
    if (titlesarr && titlesarr.length > 0) {
      setSelectedTitle(titlesarr[0].title);
    }
  }, [titlesarr]);


  useEffect(() => {




    if (daje) {
      console.log("arieccoti!");
      setisloading(true);

      //niche_2, selectedSubNiche_2, selectedTitle_2, audience_2, previewstate_2

      sendStepFN(3, niche_2, selectedSubNiche_2, selectedTitle_2, audience_2, previewstate_2);


    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    console.log(`current step is ${step}`);

  }, [step]);



  return (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "20px 110px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.20)',


          }}
        >
          {step !== 5 ? <h1>Create New Ebook</h1> : null}


          <div className="custom-loader" style={{ display: isloading ? 'block' : 'none' }}></div>
          <div id="steps" style={{ display: !isloading ? 'block' : 'none' }}>
            <div id="step1" style={{ display: step === 0 ? 'block' : 'none' }}>
              <p style={{ color: "#909090" }}>Enter your topic or niche. (Ie. Mindfulness):</p>
              <div style={{ height: "15px" }}></div>

              <div className="field">
                <p className="control has-icons-left">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"Topic or niche"}
                    id="niche"
                    name="niche"
                    onChange={(v) => {
                      setniche(v.target.value);
                    }}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-star"></i>
                  </span>
                </p>
              </div>
              <div style={{ height: "15px" }}></div>

              {errore ? <p style={{ color: "red", marginBottom: "15px" }}>{errore}</p> : null}


              <div className="field">
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(0)}
                >
                  Next
                </button>
              </div>

            </div>

            <div id="step2" style={{ display: step === 1 ? 'block' : 'none' }}>
              <p style={{ color: "#909090" }}>Select a sub-niche for your new ebook:</p>


              {subniches && subniches.map((item, index) => {
                //console.log(item.sub_niche);
                return (
                  <div className="field" key={index} style={{ display: 'block', margin: "15px 0px" }}>
                    <div className="control">
                      <label className="radio">
                        <input
                          type="radio"
                          value={item.sub_niche}
                          checked={selectedSubNiche === item.sub_niche}
                          onChange={() => setSelectedSubNiche(item.sub_niche)}
                        />
                        <span style={{ marginLeft: "0.5em" }}>{item.sub_niche}</span>
                      </label>
                    </div>
                  </div>
                );
              })}

              <div style={{ height: "15px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(0)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(1)}
                >
                  Next
                </button>
              </div>


            </div>

            <div id="step3" style={{ display: step === 2 ? 'block' : 'none' }}>
              <p style={{ color: "#909090" }}>Enter an audience for your new ebook (Ie. For parents, for restaurant owners, for women):</p>
              <div style={{ height: "15px" }}></div>

              <div className="field">
                <p className="control has-icons-left">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"Audience"}
                    id="audience"
                    name="audience"
                    onChange={(v) => {
                      setaudience(v.target.value);
                    }}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-users"></i>
                  </span>
                </p>
              </div>
              <div style={{ height: "15px" }}></div>

              {errore ? <p style={{ color: "red", marginBottom: "15px" }}>{errore}</p> : null}


              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(1)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(2)}
                >
                  Next
                </button>
              </div>

            </div>



            <div id="step3" style={{ display: step === 3 ? 'block' : 'none' }}>
              <p style={{ color: "#909090" }}>Select a title for your new ebook:</p>


              {titlesarr.map((item, index) => {
                //console.log(item.t);
                return (
                  <div className="radio" key={index} style={{ display: 'block', margin: "15px 0px" }}>
                    <label>
                      <input
                        type="radio"
                        value={item.title}
                        checked={selectedTitle === item.title}
                        onChange={() => setSelectedTitle(item.title)}
                      />
                      {" "}{item.title}
                    </label>
                  </div>
                );
              })}

              <div style={{ height: "15px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(2)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(3)}
                >
                  Next
                </button>
              </div>


            </div>



            <div id="step4" style={{ display: step === 4 ? 'block' : 'none' }}>
              <p style={{ color: "#909090" }}>Here’s a preview of the chapters and their outline:</p>
              <div style={{ height: "10px" }}></div>

              <div className="scrollable-div">


                {previewstate && previewstate.map((chapter, index) => (
                  <div key={index} style={{ marginBottom: '20px' }}>
                    <ul style={{ listStyleType: 'disc' }}>
                      <li style={{ fontWeight: 'bold' }}>
                        {Object.keys(chapter)[0]}: {chapter[Object.keys(chapter)[0]]}
                        <ul style={{ listStyleType: 'circle' }}>
                          {chapter.Outline.map((item, subIndex) => (
                            <li key={subIndex} style={{ marginLeft: '20px' }}>{item}</li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                ))}


              </div>


              <div style={{ height: "20px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(3)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(4)}
                >
                  Generate Ebook!
                </button>
              </div>


            </div>


            <div id="step5" style={{ display: step === 5 ? 'block' : 'none' }}>


              <h2 style={{ fontWeight: "600", fontSize: "1.1em" }}>Congratulations! Your ebook is being created!</h2>
              <p style={{ color: "#909090" }}>Click the button below to check the status of your new ebook.</p>
              <div style={{ height: "20px" }}></div>

              <div className="wrapperan">
                <svg className="animated-check" viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
              </div>

              <div style={{ height: "20px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    resetCreationFn();
                    history.push({
                      pathname: '/ebooks',
                    })
                  }
                  }
                >
                  Go to your Ebooks
                </button>
              </div>


            </div>


          </div>

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>

      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>Sign in or Register:
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              You need to be logged in to create a new ebook.</p>

            <div style={{ height: "15px" }}></div>


            <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
              border: 0,
              padding: "5px 15px", lineHeight: "30px", background: "#000", color: "white", borderRadius: "3px"
            }} onClick={() => {

              history.push({
                pathname: '/login',
                state: {
                  fromTutorial: true, niche, selectedSubNiche, selectedTitle, audience, previewstate
                }
              })



              //canvaRef.current.close();
              //addCustomContent();

            }}><b>I already have an account</b></button></div>

            <div style={{ height: "15px" }}></div>


            <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
              border: 0,
              padding: "5px 15px", lineHeight: "30px", background: "#fecb00", color: "#000", borderRadius: "3px"
            }} onClick={() => {


              history.push({
                pathname: '/register',
                state: {
                  fromTutorial: true, niche, selectedSubNiche, selectedTitle, audience, previewstate
                }
              })
              //addCustomContent();

            }}><b>Create a New Account</b></button></div>


          </div>

          <div style={{ height: "15px" }}></div>



        </div>
      </Popup>


    </div >);

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    error_in_creation: state.home.error_in_creation,
    sub_niches: state.home.sub_niches,
    titles: state.home.titles,
    preview: state.home.preview,
    queued: state.home.queued





  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    sendStepFN: (i, v1, v2, v3, v4, v5) =>
      dispatch(sendStep(i, v1, v2, v3, v4, v5)),
    resetErrorsFn: () => dispatch(resetErrors()),
    resetCreationFn: () =>
      dispatch(resetCreation()),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateEbook);
