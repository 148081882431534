const initState = {
  errore: null,
  recovered: null,
  ricevutomessaggio: null,
  loggedin: false,
  profile: {},
  users: [],
  convs: [],
  erroreimpostazioni: false,
  messages: [],
  token: null,
  ebooks: [],
  ebooks_admin: [],
  redirect_url: null,
  logs: [],
  stats: {},
  analytics: {},
  txs: [],
  txs_admin: [],
  settings: {},
  settings_admin: {},
  general_settings: {},
  ebook: {},
  balance: 0,
  price: 0,
  sub_niches: [],
  titles: [],
  preview: null,
  error_in_creation: null,
  queued: false,
  total: 0,
  page: 1,
  limit: 10,
  loading: false,
  edited: false
};

const homeReducer = (state = initState, action) => {



  switch (action.type) {

    case "RESET_CREATION":
      return {
        ...state,
        errore: null,
        ebooks: [],
        ebook: {},
        logs: [],
        sub_niches: [],
        titles: [],
        preview: null,
        error_in_creation: null,
        queued: false,
        edited: false,
        redirect_url: null
      };


    case "SENT_SUPPORT":
      return {
        ...state,
        errore: null,
        recovered: null,
        ricevutomessaggio: true,
      };

    case "RECOVERED":
      return {
        ...state,
        errore: null,
        recovered: true,
      };


    case "ERROR_RECOVERY":
      return {
        ...state,
        errore: "Error",
      };

    case "ERROR_LOGGING_USERNAME":
      return {
        ...state,
        errore: "There was an error trying to sign you up. Please try again.",
      };


    case "ERROR_IN_STEP":
      return {
        ...state,
        error_in_creation: action.what,
      };


    case "ERROR_EMAIL_NOT_EXIST":
      return {
        ...state,
        errore: "The e-mail address you entered does not belong to any user.",
      };

    case "ERROR_PWD_LOGIN":
      return {
        ...state,
        errore: "The password you entered does not match. Please try again",
      };

    case "ERROR_PWD":
      return {
        ...state,
        errore: "The passwords you entered do not match. Please try again.",
      };

    case "ERROR_EMAIL":
      return {
        ...state,
        errore: "The e-mail you entered is already associated with a user. ",
      };

    case "ERROR_LOGGING_IN":
      return {
        ...state,
        errore: "There was an error trying to sign you in. Please try again.",
      };



    case "RESET_ERRORS":
      return {
        ...state,
        errore: null,
        error_in_creation: null,
        recovered: null,
        ricevutomessaggio: null,
        loggedin_doi: null,

      };

    case "ERROR_LOGGING":
      return {
        ...state,
        errore: "",
      };

    case "ERROR_UPDATING_PROF":
      return {
        ...state,
        erroreimpostazioni:
          "",
      };

    case "CHANGEDPIC":
      return {
        ...state,
        profile: { ...state.profile, pic: action.d },
      };

    case "USER_PROFILE_DATA":
      return {
        ...state,
        userdata: action.d,
      };

    case "USER_PROFILE_DATA_OTHERS":
      return {
        ...state,
        userdata2: action.d,
      };





    case "REDIRECT_NOW":
      return {
        ...state,
        redirect_url: action.d,
      };


    case "USERS":
      return {
        ...state,
        users: action.d,
      };


    case "STEP_DATA_0":
      return {
        ...state,
        sub_niches: action.d,
      };


    case "STEP_DATA_1":
      return {
        ...state,
        titles: action.d,
      };

    case "STEP_DATA_2":
      return {
        ...state,
        preview: action.d,
      };


    case "STEP_DATA_3":
      return {
        ...state,
        queued: true,
      };


    case "BOOK_EDIT":
      return {
        ...state,
        edited: true,
      };


    case "MESSAGES":
      return {
        ...state,
        messages: action.d,
      };


    case "EBOOKS":
      return {
        ...state,
        ebooks: action.d,
      };

    case "EBOOKS_ADMIN":
      return {
        ...state,
        ebooks_admin: action.d,
      };

    case "LOGS":
      return {
        ...state,
        logs: action.d,
      };

    case "BALANCE":
      return {
        ...state,
        balance: action.d,
      };


    case "PRICE":
      return {
        ...state,
        price: action.d,
      };

    case "SETTINGS":
      return {
        ...state,
        settings: action.d,
      };

    case "SETTINGS_ADMIN":
      return {
        ...state,
        settings_admin: action.d,
      };


    case "GENERAL_SETTINGS":
      return {
        ...state,
        general_settings: action.d,
      };



    case "STATS":
      return {
        ...state,
        stats: action.d,
      };


    case "ANALYTICS":
      return {
        ...state,
        analytics: action.d,
      };



    case "TXS":
      return {
        ...state,
        txs: action.d,
      };

    case "TXS_ADMIN":
      return {
        ...state,
        txs_admin: action.d,
      };


    case "EBOOK":
      return {
        ...state,
        ebook: action.d,
      };

    case "CONVS":
      return {
        ...state,
        convs: action.d,
      };

    case "NOTIFICHE":
      return {
        ...state,
        notifiche_list: action.notifiche,

      };
    case "UNREADS":
      return {
        ...state,
        unreads: action.msg,
        notifiche: action.notifiche,

      };

    case "UPDATEPROF":
      return {
        ...state,
        //profile: action.d,
        profile: { ...state.profile, ...action.d },
      };


    case "LOG_USER_IN":
      return {
        ...state,
        errore: null,
        token: action.token
      };


    case "LOGGEDIN_CONFIRM":
      return {
        ...state,
        errore: null,
        loggedin: false,
        loggedin_doi: true,
      };


    case "LOGGEDIN":
      return {
        ...state,
        errore: null,
        loggedin: true,
        profile: action.d[0],
      };

    case "LOGGEDOUT":
      return {
        ...initState,
        errore: null,
        loggedin: false,
        profile: {},
        messages: null,
        convs: null,
        token: null


      };

    default:
      return state;
  }
};

export default homeReducer;
