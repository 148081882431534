import React from "react";



import { connect } from "react-redux";
import { compose } from "redux";

import { Link } from "react-router-dom";



//import Placeholder1 from "../assets/images/600.png";
import Testimonial from "../assets/images/testi.png";
import Testimonial2 from "../assets/images/testi1.png";
import Testimonial3 from "../assets/images/testi2.png";

import Video from "../assets/images/vid.mp4";
import Video2 from "../assets/images/vid.webm";


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


function Home(props) {



  return (
    <div>
      <div>

        <div className="jumbotron">
          <div className="video-background">
            <div className="video-overlay"></div>
            <video autoPlay loop muted playsInline>
              <source src={Video} />
              <source src={Video2} />

            </video>
          </div>


          <div className="container">
            <div className="columns" style={{
              backgroundImage: ``,
              backgroundSize: "cover",
              height: "55vh",
              padding: 0,
              margin: 0,
              display: "table",
              marginTop: "0px"

            }}>


              <div className="column is-7 has-text-left mobonly marginmob" style={{ paddingLeft: "20px", backgroundColor: "", display: "table-cell", verticalAlign: "middle" }}>

                <h1 style={{ fontSize: "1.9em", color: "#FECB00" }}><span style={{ color: "#FECB00", fontWeight: 600 }}>Unlock Your Imagination with Our AI-Powered eBook Generator</span></h1>
                <h1 style={{ fontSize: "1.5em", marginTop: "5px" }}><span style={{ color: "#fff" }}>Transform your ideas into captivating stories effortlessly. Our AI-driven eBook generator helps you create, edit, and publish professional-quality eBooks in minutes. Whether you're an aspiring author or an established writer, unleash the power of AI to bring your stories to life.</span></h1>
                <div style={{ height: "30px" }}></div>
                <Link
                  style={{
                    padding: "10px 20px",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  className="ovvvee"
                  to="/create"
                >
                  Generate an ebook now
                </Link>




              </div>

              <div className="column is-5 has-text-left mobonly" style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>


                {/*<img alt="video gif" src={Placeholder1} style={{ height: "350px", display: "block", textAlign: "center", margin: "auto", border: "1px solid #FECB00", borderRadius: "5px", padding: "30px" }} />*/}

                <div id="vidalytics_embed_p1BaIr6pjHnjvzvk" style={{ width: "100%", position: "relative", paddingTop: "56.25%" }}></div>


              </div>





            </div>


          </div>
        </div>

        <div style={{ position: "relative", width: " 100%", background: "#FECB00", color: "#fff", backgroundRepeat: "no-repeat", overflow: "hidden", backgroundSize: "cover", backgroundPosition: "center center", zIndex: 1 }}>
          <div className="container">
            <div className="columns is-vcentered is-multiline" style={{
              padding: 0,
              margin: 0,
            }}>


              <div className="column is-12 has-text-centered" style={{ paddingTop: "30px" }}>
                <h1 style={{ fontSize: "2em", color: "#fff" }}><span style={{ color: "#000", fontWeight: 600 }}>Thousands of users trust easyebook to create professional eBooks quickly and easily
                </span></h1>
                <div style={{ height: "5px" }}></div>
                <p style={{ fontSize: "1.2em", color: "#000" }}>
                  We asked them what they think of our software.  Here's what they had to say:

                </p>

                <div className="columns is-vcentered is-multiline" style={{
                  padding: 0,
                  margin: 0,
                }}>


                  <div className="column is-4 has-text-centered" style={{ paddingTop: "30px" }}>
                    <img alt="avatar" src={Testimonial} className="lp_avatar" />
                    <p style={{ fontWeight: 600 }}>Jennifer F.</p>
                    <p>easyebook transformed my writing process. It's quick, intuitive, and produces professional results every time!</p>
                  </div>

                  <div className="column is-4 has-text-centered" style={{ paddingTop: "30px" }}>
                    <img alt="avatar" src={Testimonial2} className="lp_avatar" />
                    <p style={{ fontWeight: 600 }}>Mark V.</p>
                    <p>easyebook has revolutionized the way I write. It's user-friendly, efficient, and consistently delivers high-quality results.</p>
                  </div>

                  <div className="column is-4 has-text-centered" style={{ paddingTop: "30px" }}>
                    <img alt="avatar" src={Testimonial3} className="lp_avatar" />
                    <p style={{ fontWeight: 600 }}>Sarah K.</p>
                    <p>With easyebook, my writing process has become seamless. It's fast, easy to use, and always yields professional-grade work.</p>
                  </div>


                </div>

                <div style={{ height: "40px" }}></div>

                <Link
                  style={{
                    padding: "10px 20px",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  className="ovvvee"
                  to="/create"
                >
                  Generate an ebook now
                </Link>
                <div style={{ height: "35px" }}></div>

              </div>







            </div>
          </div>
        </div>





        <div style={{ position: "relative", width: " 100%", background: "#fff", color: "#000", backgroundRepeat: "no-repeat", overflow: "hidden", backgroundSize: "cover", backgroundPosition: "center center", zIndex: 1 }}>
          <div className="container">
            <div className="columns is-vcentered is-multiline" style={{
              padding: 0,
              margin: 0,
            }}>


              <div className="column is-12 has-text-centered" style={{ paddingTop: "30px" }}>
                <h1 style={{ fontSize: "2em", color: "#fff" }}><span style={{ color: "#000", fontWeight: 600 }}>F.A.Q.
                </span></h1>
                <div style={{ height: "5px" }}></div>
                <p style={{ fontSize: "1.2em", color: "#000" }}>
                  Whether you're a seasoned author or a beginner, these FAQs will help you understand how easyebook can streamline your eBook creation process and enhance your productivity.

                </p>
                <div style={{ height: "15px" }}></div>

                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is easyebook?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      easyebook is an AI-powered tool designed to simplify and automate the creation of eBooks, catering to writers and publishers.
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does easyebook work?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      easyebook uses advanced AI to generate text, format content, and produce eBooks in various formats. Simply input your ideas, and the AI handles the rest.
                    </AccordionItemPanel>
                  </AccordionItem>


                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I use easyebook for different genres?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Yes, easyebook supports multiple genres and languages, allowing you to create diverse types of eBooks tailored to your specific needs.
                    </AccordionItemPanel>
                  </AccordionItem>



                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is easyebook user-friendly for beginners?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Absolutely. easyebook features a user-friendly interface designed to be intuitive, making it easy for beginners to create professional-quality eBooks.                    </AccordionItemPanel>
                  </AccordionItem>



                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I customize my eBook further?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      Yes, you can open your eBook in Canva to add custom designs, graphics, and layouts, giving your eBook a personalized touch.             </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>




                <div style={{ height: "35px" }}></div>



              </div>







            </div>
          </div>
        </div>




        <div className="container">

          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
            <p style={{ color: "black", flex: '1 1 100%', textAlign: 'center' }}>&copy; 2024 AbundanceBlueprints Ltd</p>
            <p style={{ color: "black", flex: '1 1 100%', textAlign: 'center', marginTop: "20px" }}>
              <Link style={{ color: "black", textDecoration: "underline" }} to="/privacy">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link style={{ color: "black", textDecoration: "underline" }} to="/terms">Terms</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <a style={{ color: "black", textDecoration: "underline" }} href="mailto:support@mvpmule.com">Contact us</a>
            </p>
          </div>


          <div style={{ height: "40px" }}></div>

        </div>

      </div>
    </div >);

}

const mapStateToProps = (state) => {
  return {


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
