import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_3445.svg";
import { withRouter } from "react-router-dom";
import { logOut } from "../store/actions/homeActions";
import { connect } from "react-redux";

import { compose } from "redux";

import onClickOutside from 'react-onclickoutside'





class Links extends Component {
  state = {
    toggled: false,
  };

  constructor(props) {
    super(props);
    // Create refs for each element
    this.input1Ref = React.createRef();

  }




  handleClickOutside = () => {
    //alert('onClickOutside() method called');
    let ref = "diocane";
    this.refs[ref].checked = false;
  }


  handleChange = (e) => {
    this.setState({
      toggled: e.target.checked,
    });
  };

  oii = () => {
    if (this.state.toggled) {
      let ref = "diocane";
      this.refs[ref].checked = !this.refs[ref].checked;
    }
  };

  oii2 = () => {
    let ref = "diocane";
    this.refs[ref].checked = false;
  };

  generateRandomClick = () => {
    // Get the viewport width and height
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Generate random coordinates within the viewport
    const randomX = Math.floor(Math.random() * viewportWidth);
    const randomY = Math.floor(Math.random() * viewportHeight);

    // Create a new mouse event
    const randomClickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: randomX,
      clientY: randomY,
    });

    // Dispatch the event on the document element
    document.elementFromPoint(randomX, randomY)?.dispatchEvent(randomClickEvent);
  };


  componentDidUpdate() {

    this.generateRandomClick();
  }
  //getBalance
  // console.log(this.props.location);

  render() {
    //console.log(this.props.location.pathname);


    return this.props.location.pathname !== "/login" && this.props.token ? (
      <nav
        className={`navbar is-fixed-top ${this.state.scrollTop >= 30 ? "shadow-active" : ""
          }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" onClick={this.oii2} to="/ebooks">
              <img src={logo} className="logonav" alt="Logo" />
            </Link>
            <label
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              htmlFor="nav-toggle-state"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </label>
          </div>
          <input
            ref={"diocane"}
            type="checkbox"
            id="nav-toggle-state"
            onChange={this.handleChange}
          />
          <div id="navbarBasicExample" className="navbar-menu" ref={this.input1Ref}
          >
            <div className="navbar-end">


              <Link
                className={"navbar-item blogle"}

                onClick={this.oii}
                to="/billing"

              >
            <p style={{ paddingLeft: "6px" }}><span>✨ {this.props.balance || 0}</span> Credits</p>


              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/ebook") || this.props.location.pathname.startsWith("/edit")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/ebooks"

              >
                My Ebooks
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/create")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/create"

              >
                Create New Ebook
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/billing")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/billing"

              >
                Billing
              </Link>




              <Link
                className={
                  this.props.location.pathname.startsWith("/settings")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/settings"

              >
                Settings
              </Link>



              <Link
                className={
                  this.props.location.pathname.startsWith("/mesaggi")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={() => {
                  this.props.logOut();
                  window.location.href = "/login";
                }}

                to="/"

              >
                Log Out
              </Link>






            </div>
          </div>
        </div>
      </nav>
    ) : (
      <nav
        className={`navbar is-fixed-top ${this.state.scrollTop >= 30 ? "shadow-active" : ""
          }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" onClick={this.oii2} to="/">
              <img src={logo} className="logonav" alt="logo" />
            </Link>
            <label
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              htmlFor="nav-toggle-state"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </label>
          </div>
          <input
            ref={"diocane"}
            type="checkbox"
            id="nav-toggle-state"
            onChange={this.handleChange}
          />
          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-end">










              <Link
                className={
                  this.props.location.pathname.startsWith("/mesaggi")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }
                onClick={this.oii}
                to="/create"

              >
               Generate an Ebook Now
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/mesaggi")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }
                onClick={this.oii}
                to="/register"

              >
              Create New Account
              </Link>

              

              <Link
                className={
                  this.props.location.pathname.startsWith("/mesaggi")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }
                onClick={this.oii}
                to="/login"

              >
              Login
              </Link>

              
            </div>
          </div>
        </div>
      </nav>
    )
  }
}




const mapStateToProps = (state) => {
  return {
    profile: state.home.profile,
    errore: state.home.erroreimpostazioni,
    loggedin: state.home.loggedin,
    userdata: state.home.userdata,
    token: state.home.token,
    balance: state.home.balance,




  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),


  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(onClickOutside(Links));
