import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getTXs, getPrice, getStripe, resetCreation } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Billing(props) {

  const { token, txs, getTXsFN, loading, getPriceFN, price_r, match, redirect_url, getStripeFN, resetCreationFN } = props;

  const canvaRef = useRef();
  const canvaRef2 = useRef();

  //const [amount, setamount] = useState(1);
  const [price, setprice] = useState(1);
  const [isloading, setisloading] = useState(false);

  const [selectedValue, setSelectedValue] = useState(2);

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(parseInt(event.target.value));
  };


  //const [totalRows, setTotalRows] = useState(0);
  //const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    //alert(match.params.id);
    if (match.params.id) {
      canvaRef2.current.open();

    }
    getTXsFN();
    getPriceFN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    setprice(price_r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price_r]);


  useEffect(() => {

    if (redirect_url) {
      resetCreationFN();
      window.location.href = redirect_url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect_url]);


  /*
    const handlePageChange = page => {
      getTXsFN(page, perPage);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      getTXsFN(newPerPage);
      getTXsFN(page, newPerPage);
    };
  
  */

  const columns = [

    {
      name: 'Credits',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.credits}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Amount',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{"$" + row.amount}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Method',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.method}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => parseInt(row.status) === 0 ? (<span style={{ color: "#000", fontSize: "1.2em" }}>Failed</span>) : (<span style={{ color: "#79CA27", fontSize: "1.2em" }}>Paid</span>),
      sortable: true,
    },/*
    {
      name: 'Invoice',
      selector: row => (<p><button style={{
        padding: "5px 10px",
        fontSize: "1.2em",
        fontWeight: "400",
        border: 0,
        color: "#000",
        background: "#FECB00",
        borderRadius: "3px",
      }} onClick={() => {
        window.open(row.receipt);

      }}>Get Invoice</button></p>),
      sortable: true,
    }*/
  ];


  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>




      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >

          <div className="flex-container">


            <button className="right-button" style={{
              padding: "10px 25px",
              fontSize: "1.2em",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }} onClick={() => {
              if (canvaRef.current) {
                canvaRef.current.open();

              }

            }}>
              Buy more credits
            </button>
          </div>




          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {txs === undefined || !txs || txs.length < 1 ? <p style={{ padding: "0px 20px 20px 20px" }}>You haven't made any payment yet.</p> : <DataTable
            title="My Transactions"
            columns={columns}
            data={txs}
            progressPending={loading}
            pagination={true}
            
            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={txs.length} // Total number of rows in your data
            paginationDefaultPage={1}


          //paginationTotalRows={totalRows}
          //onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>



      <Popup
        ref={canvaRef2}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>
            {match.params.id === "success" ? "Payment Succesfull" : "Payment Failed"}
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              {match.params.id === "success"
                ? "Please wait a few seconds while we process your payment and credit your account."
                : "There was an issue with your payment. Please try again."}
            </p>
            <div style={{ height: "15px" }}></div>


          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {



            canvaRef2.current.close();

          }}><b>Close</b></button>



          </div>


        </div>
      </Popup>


      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "600px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.2em" }}>Buy Credits
          </p>
            <div style={{ height: "15px" }}></div>
            <div className="billingt">
              <div className="billingt2">
                <input type="radio" id="control_01" name="select"
                  value="2"
                  checked={selectedValue === 2}
                  onChange={handleChange}
                  className="radiode"

                />
                <label htmlFor="control_01" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize:"1.2em" }}>2 Credits - $9.99</h2>
                  <p>Create 2 eBooks</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_02" name="select" value="10"
                  checked={selectedValue === 10}
                  onChange={handleChange}
                  className="radiode"
                />
                <label htmlFor="control_02" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize:"1.2em" }}>10 Credits - $29.99</h2>
                  <p>Create 10 eBooks</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_03" name="select" value="20"
                  checked={selectedValue === 20}
                  onChange={handleChange}
                  className="radiode"
                />
                <label htmlFor="control_03" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize:"1.2em" }}>20 Credits - $59.99</h2>
                  <p>Create 20 eBooks</p>
                </label>
              </div>
            </div>



            {/*<p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              Generating one eBook requires 1 credit,
              1 Credit is ${price}
        </p>
            <div style={{ height: "15px" }}></div>


            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              <input type="number" min="1" onChange={(v) => {
                setamount(v.target.value);
              }} value={amount} style={{ width: "300px", height: "50px", padding: "0px 10px" }} placeholder="Enter an amount of credits" />
            </p>
*/}
          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}>


            {/*<button disabled={isloading} className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {



            //canvaRef.current.close();
            //go2checkout();
            setisloading(true);
            getStripeFN(price, amount);

          }}><b>Pay ${(price * amount).toFixed(2)}</b></button>*/}

            <button disabled={isloading} className="" style={{
              border: 0,
              fontSize: "1.5em",
              padding: "10px 35px", lineHeight: "30px", background: "hsla(150, 75%, 45%, 1)", color: "white", borderRadius: "3px"
            }} onClick={() => {



              //canvaRef.current.close();
              //go2checkout();
              setisloading(true);
              getStripeFN(price, selectedValue);

            }}><b>Go to Checkout</b></button>


            <div style={{ height: "15px" }}></div>

            <p style={{ textDecoration: "underline", color: "#000" }} onClick={() => {

              canvaRef.current.close();

            }}>No, thanks!</p>
          </div>


        </div>
      </Popup >



    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    txs: state.home.txs,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    price_r: state.home.price,
    redirect_url: state.home.redirect_url,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTXsFN: (a, b) =>
      dispatch(getTXs(a, b)),
    getPriceFN: () =>
      dispatch(getPrice()),
    getStripeFN: (a, b) =>
      dispatch(getStripe(a, b)),
    resetCreationFN: () =>
      dispatch(resetCreation()),

  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Billing);
