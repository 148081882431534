import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getSettingsAdmin2, saveSettings_admin2 } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';



function AdminSettings(props) {

  const { token, general_settings, getSettingsAdminFN, saveSettingsAdminFN,  } = props;

  const [price, setprice] = useState("");
  const [chapters, setchapters] = useState("");
  const [outlines, setoutlines] = useState("");
  const [maxwords, setomaxwords] = useState("");



  

  useEffect(() => {

    getSettingsAdminFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (general_settings) {

      setprice(general_settings.price_per_credit);
      setchapters(general_settings.max_chapters);
      setoutlines(general_settings.max_outlines);
      setomaxwords(general_settings.max_words_per_outline);

      
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general_settings]);




  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          <h1>General Settings</h1>

          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Maximum chapters per ebook"}
                id="c"
                name="c"
                onChange={(v) => {
                  setchapters(v.target.value);
                }}
                value={chapters}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Maximum outlines per chapter"}
                id="c"
                name="c"
                onChange={(v) => {
                  setoutlines(v.target.value);
                }}
                value={outlines}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>


          <div style={{ height: "5px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Maximum words per outline"}
                id="c"
                name="c"
                onChange={(v) => {
                  setomaxwords(v.target.value);
                }}
                value={maxwords}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>


          <div style={{ height: "5px" }}></div>


          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Price per credit/ebook"}
                id="c"
                name="c"
                onChange={(v) => {
                  setprice(v.target.value);
                }}
                value={price}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </p>
          </div>


          <div style={{ height: "10px" }}></div>



          <button
            style={{
              padding: "10px 15px",
              fontSize: "1.1rem",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }}

            onClick={() => {
              saveSettingsAdminFN(chapters, outlines, price, maxwords);

            }}
          >
            Save
          </button>
          <div style={{ height: "10px" }}></div>


        </div>
      </div>




      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    general_settings: state.home.general_settings,
    ebooks: state.home.ebooks_admin,
    loading: state.home.loading,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsAdminFN: () =>
      dispatch(getSettingsAdmin2()),

    saveSettingsAdminFN: (a, b, c, d) =>
      dispatch(saveSettings_admin2(a, b, c, d)),



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminSettings);
