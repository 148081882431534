import { Fragment, useEffect } from 'react';

import { Router, Switch, Route, useLocation } from "react-router-dom";


import { createBrowserHistory } from "history";

import { useDispatch } from 'react-redux';



import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";

import Login from "./pages/Login";
import Recovery from "./pages/Recovery";
import Register from "./pages/Register";


import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";


import MyEbooks from "./pages/MyEbooks";
import CreateEbook from "./pages/CreateEbook";
import Ebook from "./pages/Ebook";
import Billing from "./pages/Billing";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import AdminDashboard from "./pages/AdminDashboard";
import AdminUsers from "./pages/AdminUsers";
import AdminPayments from "./pages/AdminPayments";
import ManageUser from "./pages/ManageUser";
import EbookAdmin from "./pages/EbookAdmin";
import AdminAnalytics from "./pages/AdminAnalytics";
import AdminSettings from "./pages/AdminSettings";
import AutoLogin from "./pages/AutoLogin";


import { getBalance } from "./store/actions/homeActions";







const history = createBrowserHistory();

history.listen((location) => {
  //ReactGA.set({ page: location.pathname });
  //ReactGA.pageview(location.pathname);
  console.log("Changed location to: " + location.pathname);
  //dispatch(getBalance());

});

function Main() {

  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
    dispatch(getBalance());
  }, [dispatch, location.pathname]);

  return (


    <div className="App">
      {location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/recovery' && <Navbar />}



      <Fragment>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/ebooks" component={MyEbooks} />
          <Route exact path="/create" component={CreateEbook} />
          <Route exact path="/edit/:id" component={Ebook} />
          <Route exact path="/billing/:id?" component={Billing} />
          <Route exact path="/autologin/:id?" component={AutoLogin} />

          
          
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/support" component={Support} />

          <Route exact path="/admin" component={AdminDashboard} />
          <Route exact path="/admin/users" component={AdminUsers} />
          <Route exact path="/admin/users/:id" component={ManageUser} />
          <Route exact path="/admin/users/ebook/:id" component={EbookAdmin} />
          <Route exact path="/admin/payments" component={AdminPayments} />
          <Route exact path="/admin/analytics" component={AdminAnalytics} />
          <Route exact path="/admin/settings" component={AdminSettings} />




          <Route exact path="/login" component={Login} />
          <Route exact path="/recovery" component={Recovery} />
          <Route exact path="/register" component={Register} />

          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />

        </Switch>
      </Fragment>
    </div>
  );
}

function App() {
  return (
    <Router history={history}>
      <Main />
    </Router>
  );
}



export default App;
