import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getLogs, getStats } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';






function AdminDashboard(props) {

  const { token, logs, getLogsFN, page, limit, loading, getStatsFN, stats } = props;


  //const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {



    getLogsFN(page, limit);
    getStatsFN(null, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);


  const handlePageChange = page => {
    getLogsFN(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getLogsFN(page, newPerPage);
  };





  const columns = [

    {
      name: 'Date',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '30%',
    },
    {
      name: 'User',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.email}</p>),
      sortable: true,
      width: '30%',
    },
    {
      name: 'Log',
      width: '40%',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.desc}</p>),
    }
  ];


  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "70px" }}>

      <div className="container2">
        <div className="box2"><p>Revenue</p><h1 style={{ fontWeight: 600 }}>${stats && stats.revenue && stats.revenue[0]['revenue']}</h1></div>
        <div className="box2"><p>Ebooks Generated</p><h1 style={{ fontWeight: 600 }}>{stats && stats.ebooks && stats.ebooks[0]['ebooks_generated']}</h1></div>
        <div className="box2"><p>User Base</p><h1 style={{ fontWeight: 600 }}>{stats && stats.users && stats.users[0]['user_base']}</h1></div>
      </div>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {logs === undefined || !logs || logs.length < 1 ? <p>No logs yet..</p> : <DataTable
            title="Logs"
            columns={columns}
            data={logs}
            progressPending={loading}
            pagination
            
            //paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}



            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={logs.length} // Total number of rows in your data
            paginationDefaultPage={1}

          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    logs: state.home.logs,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    stats: state.home.stats
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLogsFN: (a, b) =>
      dispatch(getLogs(a, b)),

    getStatsFN: (a, b) =>
      dispatch(getStats(a, b)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminDashboard);
