import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getEbook, resetCreation, editEbook_admin } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';


import { useHistory } from 'react-router-dom';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EbookAdmin(props) {

  const { token, ebook, getEbookFN, match, edited, resetCreationFn, editEbookFn } = props;

  const [editorState, seteditorState] = useState(EditorState.createEmpty());

  const history = useHistory();


  const onEditorStateChange = editorState => {
    seteditorState(editorState);
  };


  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'], // Customize which options to show
    inline: {
      options: ['bold', 'italic', 'underline'], // Only show bold, italic, and underline
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right'],
    },
    link: {
      options: [],
    },
    history: {
      options: ['undo', 'redo'],
    },
  };




  const setContent = (html) => {
    /*
    const contentState = convertFromRaw(content);
    const editorState = EditorState.createWithContent(contentState);
    seteditorState(editorState);
    */

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      seteditorState(editorState);
    }


  };

  const getContent = () => {
    const contentState = editorState.getCurrentContent();
    let de = stateToHTML(contentState);
    //console.log(de);
    return de;
  };



  useEffect(() => {

    getEbookFN(match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {

    if (edited) {

      resetCreationFn();
      /* history.push({
         pathname: '/admin/users/9',
       })
 */

      history.goBack();

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);


  useEffect(() => {

    if (ebook && ebook.content && ebook.content !== undefined) {
      setContent(ebook.content);

    }

    //console.log(ebook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebook]);





  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "40px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          <h1>Edit Ebook</h1>
          <p style={{ color: "#909090" }}>{ebook && ebook.title}</p>

          <div style={{ border: "1px solid #D7D7D7", padding: "20px", marginTop: "20px", borderRadius: "5px" }}>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              toolbar={toolbarOptions} // Apply the custom toolbar configuration
              onEditorStateChange={onEditorStateChange}
            />
          </div>


          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: "20px" }}>
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#000",
                borderRadius: "3px",
              }}

              onClick={() => {

                editEbookFn(match.params.id, getContent(), true);
              }}

            >
              Save
            </button>

            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#000",
                background: "#FECB00",
                borderRadius: "3px",
              }}
            >
              Export to Canva
            </button>
          </div>

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    ebook: state.home.ebook,
    edited: state.home.edited


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEbookFN: (a) =>
      dispatch(getEbook(a)),

    resetCreationFn: () =>
      dispatch(resetCreation()),

    editEbookFn: (a, b, c) =>
      dispatch(editEbook_admin(a, b, c)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(EbookAdmin);
