import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getTXsAdmin } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';





function AdminPayments(props) {

  const { token, getTXsAdminFN, page, limit, loading,  txs_admin } = props;


  //const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [filterText, setFilterText] = useState('');
  const [filteredData, setFilteredData] = useState(txs_admin);

  useEffect(() => {
    const filteredItems = txs_admin.filter(item =>
      item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
    );
    //console.log(filteredItems);
    setFilteredData(filteredItems);
  }, [filterText, txs_admin]);



  useEffect(() => {



    getTXsAdminFN(page, limit);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    //console.log(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txs_admin]);


  const handlePageChange = page => {
    getTXsAdminFN(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getTXsAdminFN(page, newPerPage);
  };





  const columns = [

    {
      name: 'Reference',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.id}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Method',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.method}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Amount',
      selector: row => (<p style={{ fontSize: "1.2em" }}>${row.amount}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'User',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.email}</p>),
      sortable: true,
      width: '40%',
    },
    {
      name: 'Date',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '30%',
    },
  ];


  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "70px" }}>


      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}

          {txs_admin === undefined || !txs_admin || txs_admin.length < 1 ? <p>No payments yet..</p> :
            <DataTable

              title="Manage Payments"
              columns={columns}
              data={filteredData}
              progressPending={loading}
              pagination
              paginationServer
              //paginationTotalRows={totalRows}

              paginationRowsPerPageOptions={[10, 20, 30]}
              paginationPerPage={10} // Set the default number of rows per page
              paginationTotalRows={filteredData.length} // Total number of rows in your data
              paginationDefaultPage={1}

              
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              className="my-data-table"
              subHeader
              subHeaderComponent={
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                  <input
                    type="text"
                    placeholder="Look Up Payment"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    style={{ padding: "5px" }}

                  /></div>}


            />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 AbundanceBlueprints Ltd</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    txs_admin: state.home.txs_admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTXsAdminFN: (a, b) =>
      dispatch(getTXsAdmin(a, b)),



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminPayments);
